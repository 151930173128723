import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { IoIosArrowDown, IoIosMenu } from 'react-icons/io';
import styles from './Navbar.module.scss';
import Modal from '../../components/Modal/Modal';
import Logout from '../../containers/Logout/Logout';
import classNames from 'classnames';
import _ from 'lodash';
import { askTherapists, quizOptions, useDefaultLink } from '../../helpers';
import { useLoginSelector } from '../../redux/selectors';
import Dropdown from '../../components/Dropdown/Dropdown';
import DesktopView from '../../containers/DesktopView/DesktopView';
import { MobileView } from 'react-device-detect';

interface NavbarProps {}

function Navbar(props: NavbarProps) {
  const login = useLoginSelector();
  const defaultLink = useDefaultLink();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isBusinessLanding = pathname === '/for-providers';
  const isClientLanding = pathname === '/therapists' || pathname === '/';
  const isPosts = _.includes(pathname, '/posts/');
  const isTherapistSearch = _.includes(pathname, '/therapists/');
  const isTests = _.includes(pathname, '/tests/');

  const isReverseBar =
    isBusinessLanding ||
    isClientLanding ||
    isPosts ||
    isTherapistSearch ||
    isTests;

  const askTherapistOptions = _.map(askTherapists, ({ navTitle, slug }) => ({
    label: navTitle,
    value: `/posts/${slug}`,
  }));

  const onChangeDropdown = ({ value }: any) => navigate(value);
  const activeClassName = ({ isActive }: any) =>
    isActive ? styles.active : '';

  const menuLinks = ({ className, closeModal }: any) => (
    <div onClick={closeModal} className={className}>
      {login?.id && (
        <>
          <NavLink to={defaultLink} className={activeClassName}>
            Profile
          </NavLink>

          {/* <NavLink to="/billing"  className={activeClassName}>
            Account
          </NavLink> */}

          <Logout />
        </>
      )}

      {!login?.id && (
        <>
          <NavLink className={activeClassName} to="/login">
            Login
          </NavLink>

          <DesktopView>
            <Dropdown
              buttonChildren={
                <div className={styles.dropdownLink}>
                  Ask Therapists <IoIosArrowDown />
                </div>
              }
              options={askTherapistOptions}
              onChange={onChangeDropdown}
              minWidth={280}
              ariaLabel="Ask Therapists"
              isReverse
            />

            {/* <Dropdown
              buttonChildren={
                <div className={styles.dropdownLink}>
                  Self Tests <IoIosArrowDown />
                </div>
              }
              options={quizOptions}
              onChange={onChangeDropdown}
              isReverse
            /> */}
          </DesktopView>

          {!isBusinessLanding && (
            <NavLink to="/for-providers" className={activeClassName}>
              For Providers
            </NavLink>
          )}

          {!isClientLanding && (
            <NavLink to="/therapists" className={activeClassName}>
              Find a Therapist
            </NavLink>
          )}

          <MobileView>
            <div className={styles.modalHeader}>Ask Therapists...</div>

            {_.map(askTherapistOptions, ({ label, value }) => (
              <NavLink to={value} className={activeClassName} key={value}>
                {label}
              </NavLink>
            ))}

            {/* <div className={styles.modalHeader}>Self Tests...</div>

            {_.map(quizOptions, ({ label, value }) => (
              <NavLink to={value} className={activeClassName} key={value}>
                {label}
              </NavLink>
            ))} */}
          </MobileView>
        </>
      )}
    </div>
  );

  return (
    <div
      className={classNames(styles.navbar, {
        [styles.isReverseBar]: isReverseBar,
      })}
    >
      <div className={styles.brand}>
        <Link to={defaultLink}>MintLeaf</Link>
      </div>

      <div className={styles.navActions}>
        {menuLinks({ className: styles.desktopMenu })}

        <Modal
          buttonChildren={<IoIosMenu className={styles.mobileMenu} />}
          render={({ closeButton, closeModal }) => (
            <div className={styles.modalMenu}>
              {closeButton}
              {menuLinks({ closeModal })}
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default Navbar;
