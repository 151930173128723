import { Helmet } from 'react-helmet-async';
import { statesToAbbrev } from '../../helpers';

interface HeaderTagsProps {
  title?: string;
  description?: string;
  url?: string;
  state?: string;
  noIndex?: boolean;
}

export function HeaderTags(props: HeaderTagsProps) {
  const { title, description, url, state, noIndex } = props;

  // https://github.com/staylor/react-helmet-async/issues/154
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="dcterms.title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      )}

      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )}

      {url && (
        <Helmet>
          <meta property="og:url" content={url} />
          <link rel="canonical" href={url} />
          <meta property="twitter:url" content={url} />
        </Helmet>
      )}

      {state && (
        <Helmet>
          <meta property="geo.region" content={`US-${statesToAbbrev[state]}`} />
          <meta property="geo.placename" content={state} />
        </Helmet>
      )}

      {noIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
    </>
  );
}
