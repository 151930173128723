import _ from 'lodash';

const ExternalSelect = require('react-select').default;

interface SelectProps {
  options: { value: string; label: string }[];
  onChange: Function;
  value?: any;
  placeholder?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  maxItems?: number;
  maxMenuHeight?: number;
  isOutline?: boolean;
  isClearable?: boolean;
  isRounded?: boolean;
}

function Select(props: SelectProps & any) {
  const {
    maxItems,
    onChange,
    maxMenuHeight = 315,
    isOutline = false,
    isClearable = false,
    isRounded = false,
    ...passedProps
  } = props;

  const dark1 = 'hsl(230, 35%, 98%)';
  // const dark2 = 'hsl(210, 40%, 95%)';
  const dark3 = 'hsl(230, 35%, 90%)';
  const dark4 = 'hsl(230, 35%, 85%)';
  // const dark5 = 'hsl(230, 25%, 70%)';
  const dark6 = 'hsl(230, 10%, 50%)';
  const dark8 = 'hsl(230, 10%, 30%)';
  // const green1 = 'hsl(160, 91%, 9%)';
  // const green2 = 'hsl(160, 70%, 18%)';
  const green3 = 'hsl(160, 40%, 30%)';
  // const green4 = 'hsl(160, 25%, 50%)';
  const green5 = 'hsl(160, 20%, 70%)';

  const white = '#ffffff';

  return (
    <ExternalSelect
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      menuPortalTarget={document.body}
      styles={{
        control: (provided: any, state: any) => ({
          ...provided,
          borderRadius: isRounded ? '36px' : '4px',
          border: isOutline ? `2px solid ${green5}` : `2px solid ${dark3}`,
          ':hover': isOutline ? { borderColor: white } : { borderColor: dark6 },
          backgroundColor: isOutline ? green3 : white,
          boxShadow: state.isFocused
            ? isOutline
              ? `0 0 0 1px ${green5}`
              : `0 0 0 1px ${dark6}`
            : 'none',
          cursor: 'pointer',
          padding: isOutline ? '0.4em 0.8em' : provided.padding,
        }),
        clearIndicator: (provided: any) =>
          isOutline
            ? {
                ...provided,
                color: green5,
                padding: '4px',
                ':focused': { color: white },
                ':hover': { color: white },
              }
            : provided,
        dropdownIndicator: (provided: any) =>
          isOutline
            ? {
                ...provided,
                color: green5,
                padding: '4px',
                ':focused': { color: white },
                ':hover': { color: white },
              }
            : provided,
        option: (provided: any, state: any) => {
          const isMaxItems = _.size(state.selectProps.value) >= maxItems;

          return {
            ...provided,
            opacity: isMaxItems ? 0.3 : 1,
            backgroundColor: state.isFocused && !isMaxItems ? dark1 : white,
            ':active': isMaxItems ? {} : { backgroundColor: dark3 },
            color: dark8,
            cursor: isMaxItems ? 'not-allowed' : 'pointer',
          };
        },
        menu: (provided: any) => ({
          ...provided,
          borderRadius: '6px',
          backgroundColor: white,
          border: 'none',
          boxShadow: '0 3px 1.71429rem rgba(46, 48, 56, 0.2)',
          minWidth: 150,
          overflow: 'hidden',
        }),
        multiValue: (provided: any) => ({
          ...provided,
          backgroundColor: dark4,
          borderRadius: isRounded ? '8px' : '4px',
          fontSize: '16px',
        }),
        placeholder: (provided: any) => ({
          ...provided,
          color: isOutline ? dark1 : dark6,
          fontWeight: isOutline ? 600 : 400,
          top: '48%',
        }),
        valueContainer: (provided: any) => ({
          ...provided,
          padding: '0 0.5em',
        }),
        indicatorSeparator: (provided: any) =>
          isClearable
            ? isOutline
              ? { ...provided, width: '2px', backgroundColor: green5 }
              : provided
            : { display: 'none' },
        input: (provided: any) =>
          isOutline ? { ...provided, color: white } : provided,
        singleValue: (provided: any) =>
          isOutline ? { ...provided, color: white } : provided,
      }}
      onChange={(selection: any) => {
        if (!maxItems || _.size(selection) <= maxItems) {
          onChange(selection);
        }
      }}
      aria-label={props.placeholder}
      {...passedProps}
    />
  );
}

export default Select;
