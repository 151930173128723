// https://reacttraining.com/react-router/web/guides/scroll-restoration
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function PageScrollToTop() {
  const { pathname, hash } = useLocation();
  const [current, setCurrent] = useState(pathname);
  const [previous, setPrevious] = useState('');

  useEffect(() => {
    if (current !== pathname) {
      setPrevious(current);
      setCurrent(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    const isPaging =
      _.includes(pathname, '/therapists/') || _.includes(pathname, '/posts/');
    const isPreviousProfile = _.includes(previous, '/therapist/');
    const noScroll = isPaging && isPreviousProfile;

    if (!noScroll && !hash) {
      scrollToTop();
    }
  }, [previous]);

  return null;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
