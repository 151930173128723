import _ from 'lodash';
import Skeleton from '../../components/Skeleton/Skeleton';
import { UserDoc } from '../../../src-server/models';
import User from '../User/User';
import PagingNav from '../PagingNav/PagingNav';
import { useLoadDocList } from '../../hooks/useLoadDoc';
import styles from './UserPage.module.scss';

interface UserPageProps {
  params: any;
  createUpdatePage: Function;
}

function UserPage(props: UserPageProps) {
  const { params, createUpdatePage } = props;
  const { result, isSuccess } = useLoadDocList({
    name: 'UserPage',
    collection: 'user',
    query: params,
  });

  if (!isSuccess || !result) {
    return (
      <>
        {_.times(params.pageSize, (index) => (
          <Skeleton key={index} count={30} card />
        ))}
      </>
    );
  }

  const { docs, next, page } = result;
  const userDocs: UserDoc[] = docs;

  return (
    <>
      {_.isEmpty(userDocs) && page === 1 ? (
        <div className={styles.notFound}>
          No therapists found. Please try widening your search.
        </div>
      ) : (
        _.map(userDocs, (userDoc) => (
          <User short_id={userDoc.short_id} key={userDoc.id} />
        ))
      )}

      <PagingNav page={page} createUpdatePage={createUpdatePage} next={next} />
    </>
  );
}

export default UserPage;
