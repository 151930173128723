import { useState } from 'react';
import ReactGA from 'react-ga4';
// import ReactPixel from 'react-facebook-pixel';
import Modal from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { axiosPost } from '../../hooks/useAxios';
import Textarea from '../../components/Textarea/Textarea';
import Evil from '../../components/Evil/Evil';
import { useIsMyself } from '../../redux/selectors';

import modalStyles from '../../components/Modal/Modal.module.scss';
import styles from './ContactForm.module.scss';

interface ContactFormProps {
  targetUser: string;
  therapistName: string;
  buttonChildren: any;
  buttonClassName?: string;
}

function ContactForm(props: ContactFormProps) {
  const { targetUser, therapistName, buttonChildren, buttonClassName } = props;

  const [isRobot, setIsRobot] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const isMyself = useIsMyself(targetUser);

  const onSubmit = () => {
    const hasAllFields = name && email && message;

    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    if (!hasAllFields) {
      setErrorMessage('Please fill out all required fields and try again.');
      return;
    }

    axiosPost('/api/contact', {
      recipient_user: targetUser,
      author_email: email,
      phone,
      name,
      message,
      is_phone: false,
    });

    if (!isMyself) {
      ReactGA.event({ category: 'Conversion', action: 'Therapist Contact' });
      // ReactPixel.trackCustom('Therapist Contact');
    }

    setSuccess(true);
  };

  const createOnFinish = (closeModal: any) => () => {
    setSuccess(false);
    closeModal();
  };

  const createSuccessMessage = (closeModal: any) => (
    <div className={modalStyles.form}>
      <div className={modalStyles.header}>Message Sent</div>
      <div>
        Your message has sent succesfully. Please keep an eye out for a email{' '}
        {phone ? 'or phone call' : ''} reply from the therapist soon. We wish
        you all the best.
      </div>

      <div className="faded">
        Your friend, <br />
        Team MintLeaf
      </div>

      <div className="form-actions">
        <Button className="btn" onClick={createOnFinish(closeModal)}>
          Got it
        </Button>
      </div>
    </div>
  );

  const createForm = (closeModal: any) => (
    <div className={modalStyles.form}>
      <div>
        <div className={modalStyles.header}>Email {therapistName}</div>

        <div className={styles.explanation}>
          Answer some questions below and {therapistName} will be in touch to
          schedule a brief consultation about your needs.
        </div>
      </div>

      <div className="form-field">
        <span className="form-label">Name</span>
        <input
          name="name"
          className="form-input"
          placeholder="Your name"
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          data-private
        />
      </div>

      <div className="form-field">
        <span className="form-label">What can I help you with?</span>
        <div className={styles.contactMessage} data-private>
          <Textarea
            placeholder="What brings you to therapy?"
            value={message}
            onChange={setMessage}
          />
        </div>
      </div>

      <div className="form-field">
        <span className="form-label">How can {therapistName} reach you?</span>
        <div className="form-field-split">
          <input
            name="email"
            type="email"
            className="form-input"
            placeholder="Your email"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            data-private
          />

          <input
            name="phone"
            type="tel"
            className="form-input"
            placeholder="Your phone (optional)"
            value={phone}
            onChange={(event) => setPhone(event.currentTarget.value)}
            data-private
          />
        </div>
      </div>

      <Evil setIsRobot={setIsRobot} />

      {errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <div className="form-actions">
        <Button className="btn" color="grey" onClick={closeModal}>
          Cancel
        </Button>

        <Button className="btn" onClick={onSubmit}>
          Send Email
        </Button>
      </div>

      <div className={styles.note}>
        MintLeaf does NOT retain a copy of your message or read them, although
        we will forward you a copy for your records. Emails are not a secure
        means of communication and personal health information should not be
        sent. Spam filters may prevent the therapist from receiving your email.
        If you are dealing with an emergency call 911.
      </div>
    </div>
  );

  return (
    <Modal
      buttonClassName={buttonClassName}
      buttonChildren={buttonChildren}
      render={({ closeModal }) => {
        return success
          ? createSuccessMessage(closeModal)
          : createForm(closeModal);
      }}
    />
  );
}

export default ContactForm;
