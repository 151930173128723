import _ from 'lodash';
import styles from './Paging.module.scss';
import { scrollToTop } from '../../hooks/ScrollToTop';
import { useSearchParams } from 'react-router-dom';

interface PagingProps {
  component: any;
  pageProps?: any;
  params?: any;
  className?: string;
  showAllPages?: boolean;
}

function Paging(props: PagingProps) {
  const {
    component: PageComponent,
    params = {},
    pageProps,
    className = styles.pageGrid,
    showAllPages = true,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const page = _.toNumber(searchParams.get('page')) || 1;

  const createUpdatePage = (update: number) => {
    return () => {
      searchParams.set('page', _.toString(update));
      setSearchParams(searchParams, { replace: showAllPages });

      if (!showAllPages) {
        scrollToTop();
      }
    };
  };

  const renderPage = (currPage: number) => (
    <PageComponent
      key={currPage}
      params={{ ...params, page: currPage }}
      nextPage={currPage === page ? createUpdatePage(page + 1) : undefined}
      createUpdatePage={createUpdatePage}
      {...pageProps}
    />
  );

  return (
    <div className={className}>
      {showAllPages
        ? _.map(_.range(1, page + 1), renderPage)
        : renderPage(page)}
    </div>
  );
}

export default Paging;
