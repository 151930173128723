import { configureStore } from '@reduxjs/toolkit';
import { loginReducer, collectionsReducer } from './reducers';
import axios from '../hooks/useAxios';

const store = configureStore({
  reducer: { login: loginReducer, collections: collectionsReducer },
});

function persistLogin() {
  const { token, id, short_id, username, url_tag } = store.getState().login;

  if (token && id && username) {
    localStorage.setItem('token', token);
    localStorage.setItem('id', id);
    localStorage.setItem('username', username);

    if (short_id) {
      localStorage.setItem('short_id', short_id);
    }

    if (url_tag) {
      localStorage.setItem('url_tag', url_tag);
    }

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('username');

    localStorage.removeItem('short_id');
    localStorage.removeItem('url_tag');

    axios.defaults.headers.common.Authorization = null;
  }
}

persistLogin();

store.subscribe(persistLogin);

export default store;
