import ReactModal from 'react-modal';
import { IoIosClose } from 'react-icons/io';
import styles from './Modal.module.scss';

interface ControlledModalProps {
  render: (params: any) => any;
  closeModal: any;
  isOpen: boolean;
  match?: any;
  location?: any;
  history?: any;
}

ReactModal.setAppElement('#root');

function ControlledModal(props: ControlledModalProps) {
  const { render, closeModal, isOpen, match, location, history } = props;

  const body = document.querySelector('body');

  if (body) {
    isOpen
      ? body.classList.add(styles.modalOpen)
      : body.classList.remove(styles.modalOpen);
  }

  const closeButton = (
    <div className={styles.closeButton} onClick={closeModal}>
      <IoIosClose />
    </div>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      {render({ closeModal, match, location, history, closeButton })}
    </ReactModal>
  );
}

export default ControlledModal;
