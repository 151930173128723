import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Skeleton from '../../components/Skeleton/Skeleton';
import styles from './UserBadge.module.scss';
import { getDisplayName, getProfileLink } from '../../helpers';
import { useDocSelector } from '../../redux/selectors';

interface UserBadgeProps {
  user?: string;
  className?: string;
  link?: boolean;
}

function UserBadge(props: UserBadgeProps) {
  const { user, className = 'badge', link = true } = props;
  const userDoc = useDocSelector({ collection: 'user', id: user });

  if (!userDoc) {
    return <Skeleton card count={6} />;
  }

  const { photo } = userDoc;
  const displayName = getDisplayName(userDoc);
  const letter = _.first(displayName);
  const params = {
    className: classNames(className, {
      [styles.noPhoto]: !photo,
      [styles.hasPhoto]: photo,
    }),
    to: getProfileLink(userDoc),
  };
  const children = photo ? <img src={photo} alt={displayName} /> : letter;

  return link ? (
    <Link {...params}>{children}</Link>
  ) : (
    <div {...params}>{children}</div>
  );
}

export default UserBadge;
