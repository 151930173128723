import _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { loginAction, logoutAction, loadDocsAction } from './actions';

export interface LoginStore {
  token: string | null;
  id: string | null;
  username: string | null;
  short_id?: string | null;
  url_tag?: string | null;
}

interface CollectionsStore {
  [collection: string]: { [docId: string]: any };
}

export const loginReducer = createReducer<LoginStore>(
  {
    token: localStorage.getItem('token'),
    id: localStorage.getItem('id'),
    short_id: localStorage.getItem('short_id'),
    username: localStorage.getItem('username'),
    url_tag: localStorage.getItem('url_tag'),
  },
  (builder) => {
    builder
      .addCase(loginAction, (state, action) => {
        const { token, id, short_id, username, url_tag } = action.payload;
        state.token = token;
        state.id = id;
        state.username = username;
        state.short_id = short_id;
        state.url_tag = url_tag;
      })
      .addCase(logoutAction, (state) => {
        state.token = null;
        state.id = null;
        state.username = null;
        state.short_id = null;
        state.url_tag = null;
      });
  },
);

export const collectionsReducer = createReducer<CollectionsStore>(
  {},
  (builder) => {
    builder.addCase(loadDocsAction, (state, action) => {
      const { docs, collection } = action.payload;

      _.each(docs, (doc) => {
        if (collection === 'user') {
          state[collection] = {
            ...state[collection],
            [doc.id]: doc,
            [doc.short_id]: doc,
            [doc.username]: doc,
          };
        } else {
          state[collection] = { ...state[collection], [doc.id]: doc };
        }
      });
    });
  },
);
