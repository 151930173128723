import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import App from './App.tsx';

import 'normalize.css';
import './index.scss';
import store from './redux/store';

const rootElement = document.getElementById('root');
const init = rootElement.hasChildNodes() ? hydrate : render;

init(
  // <React.StrictMode>
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
  // </React.StrictMode>,
  rootElement,
);
