import _ from 'lodash';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import responseStyles from '../../containers/Response/Response.module.scss';

interface TextareaProps {
  value?: any;
  placeholder?: string;
  onChange?: any;
  className?: string;
  disabled?: boolean;
}

export function shouldTruncate({ value = '', short = false }) {
  const numberNewline = _.size(_.split(value, /\n/g));
  const maxLength = short ? 200 : 1000;
  const maxLines = short ? 5 : 10;

  return _.size(value) > maxLength || numberNewline > maxLines;
}

const Textarea = (props: TextareaProps) => {
  const { value, placeholder, onChange, className, disabled } = props;

  const limitedOnChange = (event: any) => {
    const value = event.currentTarget.value;

    if (_.size(value) < 7000) {
      onChange(value);
    }
  };

  return disabled ? (
    <div className={classNames(responseStyles.sectionText, className)}>
      {value}
    </div>
  ) : (
    <TextareaAutosize
      className={classNames(responseStyles.sectionText, className)}
      placeholder={placeholder}
      onChange={limitedOnChange}
      minRows={2}
      value={value || ''}
    />
  );
};

export default Textarea;
