import { useNavigate } from 'react-router-dom';
import styles from './User.module.scss';
import { getDisplayName, getTitle, getProfileLink } from '../../helpers';
import UserBadge from '../UserBadge/UserBadge';
import Skeleton from '../../components/Skeleton/Skeleton';
import Truncate from '../../components/Truncate/Truncate';
import Textarea, { shouldTruncate } from '../../components/Textarea/Textarea';
import { useLoadDoc } from '../../hooks/useLoadDoc';
import _ from 'lodash';
import { useDocSelector } from '../../redux/selectors';
import Post from '../Post/Post';
import ContactCTA from '../ContactCTA/ContactCTA';
import { isMobileOnly } from 'react-device-detect';
import { UserChip } from '../UserChip/UserChip';

interface UserProps {
  id?: string;
  short_id?: string;
}

function User(props: UserProps) {
  const { id, short_id } = props;
  const { doc: userDoc } = useLoadDoc({
    name: 'User',
    collection: 'user',
    id: id || short_id,
    query: id ? { id } : { short_id },
  });

  const responseDoc = useDocSelector({
    collection: 'response',
    id: _.first(userDoc?.response_ids),
  });

  const navigate = useNavigate();
  const short = !responseDoc && isMobileOnly;

  if (!userDoc) {
    const count = short ? 22 : 27;
    return <Skeleton card count={count} />;
  }

  const name = getDisplayName(userDoc);
  const url = getProfileLink(userDoc);
  const title = getTitle(userDoc);

  const { summary = '' } = userDoc;
  const responseType = responseDoc?.type;
  const responseText = responseDoc?.text || '';
  const responseTitle = responseDoc?.title || '';

  if (responseType === 'video' || responseType === 'audio') {
    return (
      <Post responseDoc={responseDoc} author_id={userDoc.id} key={userDoc.id} />
    );
  }

  return (
    <div className={styles.user} onClick={() => navigate(url)}>
      <UserBadge user={userDoc.id} className={styles.userBadge} link={false} />

      <div className={styles.userInfo}>
        <div className={styles.name}>{name}</div>
        <div className={styles.title}>{title}</div>

        <UserChip userDoc={userDoc} />

        <div>
          <Truncate shouldTruncate hideMore short={short}>
            {responseType === 'text' && (
              <>
                <div className={styles.responseTitle}>{responseTitle}</div>
                <Textarea value={responseText} disabled />
                <div className={styles.divider}></div>
              </>
            )}

            <Textarea value={summary} disabled />
          </Truncate>
        </div>

        <div className={styles.userAction} onClick={(e) => e.stopPropagation()}>
          <ContactCTA targetUser={userDoc.id} />
        </div>
      </div>
    </div>
  );
}

export default User;
