import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../redux/actions';
import { axiosPost } from '../../hooks/useAxios';

interface LogoutProps {}

function Logout(props: LogoutProps) {
  const dispatch = useDispatch();

  const logout = () => {
    axiosPost('/auth/logout', {});
    dispatch(logoutAction());
  };

  return (
    <NavLink to="/" className="faded" onClick={logout}>
      Logout
    </NavLink>
  );
}

export default Logout;
