import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export default function Tooltip(props: TippyProps) {
  const { children } = props;

  return (
    <Tippy arrow maxWidth={200} {...props}>
      <span>{children}</span>
    </Tippy>
  );
}
